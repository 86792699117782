import { Coordinate } from './../../../Models/coordinate.model';
import { CoordinateService } from './../../../Services/coordinate.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng-lts/dynamicdialog';
import { AffectationPoint } from './../../../Models/affectationPoint.model';
import { Component, OnInit } from '@angular/core';
import { AffectationPointService } from '../../../Services/affectation-point.service';

@Component({
  selector: 'app-affectation-point-form-modal',
  templateUrl: './affectation-point-form-modal.component.html',
  styleUrls: ['./affectation-point-form-modal.component.scss']
})
export class AffectationPointFormModalComponent implements OnInit {
  affectationPoint:AffectationPoint
  update:boolean
  constructor(
    public ref   : DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _affectationPointService: AffectationPointService,
    private _coordinateService :CoordinateService
  ) {
    this.affectationPoint = config.data?.affectationPoint;
    this.update = config.data?.update;
  }

  ngOnInit() {
  }
  async saveEvent($event) {
    !$event ?  this.ref.destroy():null;
    const affectationPoint = await this.saveAffectationPoint($event,this.update);
  }
  async saveAffectationPoint(affectationPoint:AffectationPoint,update:boolean):Promise<any>{
    if (affectationPoint) {


        const newEdit = !update ? await this._affectationPointService.saveAffectationPoint(affectationPoint) :
                         await this._affectationPointService.updateAffectationPoint(affectationPoint);

        newEdit ? this.ref.close(newEdit):null;
        return newEdit?newEdit:null;
    }
    return null;
  }

  async saveCoordinate(coordinate:Coordinate,update:boolean):Promise<boolean>{
    try {
      const newCoordinate = !update? await this._coordinateService.saveCoordinate(coordinate):
                                           this._coordinateService.updateCoordinate(coordinate);      ;

                                           return newCoordinate? true:false;
    } catch (error) {
      return false;
    }
  }

}
