import { Country } from './../../../Models/country.model';
import { CountryService } from './../../../Services/country.service';
import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User, Role } from '../../../Models/user.model';
import { ToastrService } from 'ngx-toastr';
import { RoleService } from '../../../Services/role.service';
import { UserForm } from '../../../../dto/user-form';
import { Gender } from '../../../../app/Models/gender.model';

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"],
})
export class UserFormComponent implements OnInit {
  @Input("user") user: User;
  @Output() userFormSubmit = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  userForm: FormGroup;
  countries: Country[];
  image: File;
  files: File[] = [];
  roles: {name:string,role:string}[] = [
    {name:'Administrador',role:'admin'},
    {name:'Super administrador',role:'superAdmin'},
    {name:'Monitor',role:'user'}];
  genders: Gender[] = [
    {value:'M',label: 'Masculino' },
    {value:'F',label: 'Femenino' },
    {value:'B',label: 'Binario' },
    {value:'X',label: 'No responde' },
];
  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _roleService: RoleService,
    private _countryService: CountryService
  ) {}

  ngOnInit() {
    this.getCountries();
    //his.getRoles();
    this.setForm(this.user);
  }
  async getCountries() {
    try {
      this.countries = await this._countryService.getCountries();
    } catch (error) {
      console.log(error);
    }
  }
 
  setForm(user?: User) {
    const validatorPassword = user?.id
      ? null
      : Validators.compose([Validators.required, Validators.minLength(6)]);
    this.userForm = this._formBuilder.group({
      id: [user ? user.id : null],
      password: [null, validatorPassword],
      country: [user ? user.country : null, Validators.required],
      gender: [user ? user.gender : 'X',],
      job: [user ? user.job : null, Validators.required],
      roles: [user?user.roles:null,Validators.required],
      password2: [null, validatorPassword],
      name: [
        user ? user.name : "",
        [Validators.required, Validators.minLength(3)],
      ],
      lastName: [
        user ? user.lastName : "",
        [Validators.required, Validators.minLength(3)],
      ],
      identification: [
        user ? user.identification : null,
        [Validators.required, Validators.minLength(5)],
      ],
      email: [user ? user.email : "", [Validators.required, Validators.email]],
      phoneNumber: [
        user ? user.phoneNumber : "",
        [Validators.required, Validators.minLength(8)],
      ],
    });
  }

  submitForm() {
    if (this.userForm.valid) {
      let form = this.userForm.value;
      if (
        (!form.id && form.password != form.password2) ||
        (form.id && form.password && form.password != form.password2)
      ) {
        this._countryService.showMessage(
          this.toastr,
          "top",
          "center",
          "Las contraseñas no coinciden",
          "error",
          "Error en el formulario"
        );
      } else {
        if(form.id && !form.password) delete form.password
        delete form.password2
        this.userFormSubmit.emit({
          user: form,
          file: this.image,
        } as UserForm);
      }
    } else {
      this.toastr.error(
        "Por favor, complete todos los campos requeridos",
        "Error"
      );
    }
  }
  cancel() {
    this.cancelEvent.emit();
  }
  onUpload(event) {
    console.log(event);
  }
  onSelect(event) {
    this.image = event.files.item(0);
  }
}
