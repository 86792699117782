import { UserService } from './../../../Services/user.service';
import { DataSelectsMonitoring } from './../../../../dto/data-selects-monitoring';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Monitoring } from '../../../Models/monitoring.model';
import { Answer } from '../../../Models/answer.model';

@Component({
  selector: 'app-answer-form',
  templateUrl: './answer-form.component.html',
  styleUrls: ['./answer-form.component.scss']
})
export class AnswerFormComponent implements OnInit {

  answerForm:FormGroup;
  @Input() monitoring:Monitoring;
  @Input() answer:Answer;
  @Output() saveAnswer = new EventEmitter<Answer>();
  evaluationOptions: DataSelectsMonitoring[] = []
  generalOptions: DataSelectsMonitoring[] = []
  constructor(
    private _formBuilder:FormBuilder,
    private _user : UserService,
  ) { }

  ngOnInit() {
    this.setOptions()
    this.setForm(this.answer)
  }

  setOptions(){
    this.evaluationOptions = [
      { value: 'INSIGNIFICANTES', name: 'INSIGNIFICANTES' },
      { value: 'MENORES',         name: 'MENORES' },
      { value: 'SIGNIFICATIVAS',  name: 'SIGNIFICATIVAS' },
    ]
    this.generalOptions = [
      {name:'NINGUNA CAPACIDAD',value:'NINGUNA CAPACIDAD'},
      {name:'CAPACIDAD BAJA',value:'CAPACIDAD BAJA'},
      {name:'CAPACIDAD MEDIA',value:'CAPACIDAD MEDIA'},
      {name:'CAPACIDAD ALTA',value:'CAPACIDAD ALTA'},
    ]
  }
  setForm(answer:Answer){
    this.answerForm = this._formBuilder.group({
      id:        [answer?.id??null],
      evaluation:[answer?.evaluation??'',Validators.required],
      staff:     [answer?.staff??'',Validators.required],
      time:      [answer?.time??'',Validators.required],
      budget:    [answer?.budget??'',Validators.required],
      resources: [answer?.resources??'',Validators.required],
      knowledge: [answer?.knowledge??'',Validators.required],
    })
  }

 async  submitForm(){
    if (this.answerForm.valid) {
      let formData = this.answerForm.value;
      formData['user'] = await this._user.getMe();
      formData['monitoring'] = this.monitoring;
      if (formData['id'] == null) delete formData['id']
      this.saveAnswer.emit(formData as Answer)
    }
  }

  cancel(){
    this.saveAnswer.emit(null)
  }

}
