import { Response } from './../../dto/response';
import { Area } from './../Models/area.model';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from '../../../src/dto/pagination';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends BasicService {

constructor(
  private http: HttpClient,
  private toastr: ToastrService

) { super() }

async getAreas(countryId:string = 'all',params?:Pagination):Promise<{total:number,areas:Area[]} | Area[]>{
    try{
      const result = (await this.requestMethod(this.http,'/area/'+countryId,params,'get').toPromise());
      if(result){
        return params ?result as {total:number,areas:Area[]}:result as Area[] ;
      }
      return null;
    }catch(e){
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');
    }
  return null
  }
async updateArea(area:Area):Promise<Area>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/area/'+area.id,area,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Area ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }
 async saveArea(area:Area):Promise<Area>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/area',area,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Area ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }
  async deleteArea(id:number):Promise<boolean>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/area/'+id,null,'delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return true ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return false;
    }catch(e){
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return false
  }
}
