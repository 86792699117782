import { ScheduleFormComponent } from './forms/schedule-form/schedule-form.component';
import { RoleFormModalComponent } from './modals/role-form-modal/role-form-modal.component';
import { RoleFormComponent } from './forms/role-form/role-form.component';
import { GalleryModalComponent } from './modals/gallery-modal/gallery-modal.component';
import { AnswerFormModalComponent } from './modals/answer-form-modal/answer-form-modal.component';
import { MonitoringFormModalComponent } from './modals/monitoring-form-modal/monitoring-form-modal.component';
import { MonitoringFormComponent } from './forms/monitoring-form/monitoring-form.component';
import { AffectationPointFormComponent } from './forms/affectation-point-form/affectation-point-form.component';
import { AreaFormModalComponent } from './modals/area-form-modal/area-form-modal.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CountryFormComponent } from './forms/country-form/country-form.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimeNgModule } from '../primeNG.module';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { UserFormModalComponent } from './modals/user-form-modal/user-form-modal.component';
import { UserFormComponent } from './forms/user-form/user-form.component';
import { BasicFormModalComponent } from './modals/basic-form-modal/basic-form-modal.component';
import { BasicFormComponent } from './forms/basic-form/basic-form.component';
import { AreaFormComponent } from './forms/area-form/area-form.component';
import { AffectationPointFormModalComponent } from './modals/affectation-point-form-modal/affectation-point-form-modal.component';
import { AnswerFormComponent } from './forms/answer-form/answer-form.component';
import { ScheduleModalComponent } from './modals/schedule-modal/schedule-modal.component';
import { GeneralFilterComponent } from './Filters/general-filter/general-filter.component';
import { UserFilterComponent } from './Filters/user-filter/user-filter.component';
import { AffectationPointFilter } from '../../dto/affectation-point-filters';
import { AffectationPointFilterComponent } from './Filters/affectation-point-filter/affectation-point-filter.component';
import { InstitutionalResponseFilterComponent } from './Filters/Institutional-response-filter/Institutional-response-filter.component';
import { ReportAffectationPointFilterComponent } from './Filters/report-affectation-point-filter/report-affectation-point-filter.component';
import { ChartComponent } from './chart/chart.component';
import { ChartsModule } from 'ng2-charts';



@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    PrimeNgModule,
    ChartsModule,

  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    DynamicTableComponent,
    UserFormModalComponent,
    UserFormComponent,
    CountryFormComponent,
    BasicFormModalComponent,
    BasicFormComponent,
    AreaFormComponent,
    AreaFormModalComponent,
    AffectationPointFormModalComponent,
    AffectationPointFormComponent,
    MonitoringFormComponent,
    MonitoringFormModalComponent,
    AnswerFormComponent,
    AnswerFormModalComponent,
    GalleryModalComponent,
    RoleFormComponent,
    RoleFormModalComponent,
    ScheduleFormComponent,
    ScheduleModalComponent,
    GeneralFilterComponent,
    UserFilterComponent,
    AffectationPointFilterComponent,
    InstitutionalResponseFilterComponent,
    ReportAffectationPointFilterComponent,
    ChartComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    DynamicTableComponent,
    BasicFormComponent,
    AnswerFormComponent,
    ScheduleFormComponent,
    GeneralFilterComponent,
    UserFilterComponent,
    AffectationPointFilterComponent,
    InstitutionalResponseFilterComponent,
    ReportAffectationPointFilterComponent,
    ChartComponent
  ],
  entryComponents: [
    UserFormModalComponent,
    BasicFormModalComponent,
    AreaFormModalComponent,
    MonitoringFormModalComponent,
    AnswerFormModalComponent,
    GalleryModalComponent,
    ScheduleModalComponent,
    RoleFormModalComponent
],
schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
