import { CONFIG } from './../../../config/config';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng-lts/dynamicdialog';
import { AylluFile } from './../../../Models/AylluFile.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit {
  images:AylluFile
  url:string = `${CONFIG.url_api}`

  responsiveOptions:any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.images = this.config.data;
  }

  ngOnInit() {
  }

}
