import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AffectationPointService } from '../../../Services/affectation-point.service';
import { AreaService } from '../../../Services/area.service';
import { FactorService } from '../../../Services/factor.service';
import { VariableService } from '../../../Services/variable.service';
import { Area } from '../../../Models/area.model';
import { AffectationPoint } from '../../../Models/affectationPoint.model';
import { Factor } from '../../../Models/factors.model';
import { Variable } from '../../../Models/variables.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Country } from '../../../Models/country.model';
import { CountryService } from '../../../Services/country.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-report-affectation-point-filter',
  templateUrl: './report-affectation-point-filter.component.html',
  styleUrls: ['./report-affectation-point-filter.component.scss']
})
export class ReportAffectationPointFilterComponent implements OnInit {
  areas:Area[];
  affectationPoints:AffectationPoint[]
  factors:Factor[];
  variables:Variable[];
  countries: Country[];
  filterForm:FormGroup;
  @Output() selectedAffectation = new EventEmitter();
  constructor(
    private _areaService:AreaService,
    private _affectationPointService: AffectationPointService,
    private _factorService: FactorService,
    private _variableService: VariableService,
    private _countryService: CountryService,
    private _formBuilder:FormBuilder,
    private toast :ToastrService
  ) { }

  ngOnInit() {
    this.getData();

  }
 
  initForm(){
    this.filterForm = this._formBuilder.group({
      areaId: [],
      factorId: [],
      variableId: [],
      countryId: [4],
      affectationPointId: []
    })
  }
  async getData(){
    await this.getAreas();
    await this.getFactors();
    await this.getCountries();
    this.initForm();
  }
  async getAreas(){
    this.areas = await this._areaService.getAreas(this.filterForm?this.filterForm.get('countryId').value: 4 ) as Area[];
  }
  async getFactors(){
    this.factors = await this._factorService.getFactors() as Factor[];
  }
  async getVariables(){
    this.variables = await this._variableService.getVariables(this.filterForm.get('factorId').value) as Variable[];
  }
  async getAffectationPoints(){
    const filters = this._areaService.removeNullKeys(this.filterForm.value);
    console.log(filters);
    
    this.affectationPoints = await this._affectationPointService.getAffectationPoints('all',filters) as AffectationPoint[];
  }
  async getCountries(){
    this.countries = await this._countryService.getCountries() as Country[];
  }
  generateReport(){
    const formValue = this.filterForm.value;
    if(formValue.affectationPointId){      
      this.selectedAffectation.emit(formValue.affectationPointId['id']);
    }else {
      this._affectationPointService.showMessage( this.toast,'top','center','Seleccione un punto de afectación para generar el reporte','alert-danger','Error');
    }

  }
}
