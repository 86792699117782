import { Response } from "./../../dto/response";
import { Injectable } from "@angular/core";
import { BasicService } from "./basic.service";
import { HttpClient } from "@angular/common/http";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { Country } from "../Models/country.model";

@Injectable({
  providedIn: "root",
})
export class CountryService extends BasicService {
  constructor(private http: HttpClient, private toastr: ToastrService) {
    super();
  }
  async getCountries():Promise<Country[]>{
    try{
      const result : Country[] = (await this.requestMethod(this.http,'/country',null,'get').toPromise()) as Country[];
      if(result){
        return result;
      }
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.error.message,'alert-danger','Error');

    }
    return null
  }

  async updateCounty(country:Country):Promise<Country>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/country/'+country.id,country,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Country ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }
}
