import { Injectable } from '@angular/core';
import {UUID} from 'uuid-generator-ts';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {CONFIG} from '../config/config'
import { ToastrService } from 'ngx-toastr';

export class BasicService {
  private currentUrl = CONFIG.url_api;
  private headers;
constructor( ) { }

  generateUUID(){
    return new UUID().toString()
  }

  // getCountries(){
  //   return [
  //     {id:1,name:'ARGENTINA'},
  //     {id:2,name:'BOLIVIA'},
  //     {id:3,name:'CHILE'},
  //     {id:4,name:'COLOMBIA'},
  //     {id:5,name:'ECUADOR'},
  //     {id:6,name:'PERU'},
  //    ]
  // }

    /**
   * Method to redirect method request
   * @param url  url to request
   * @param param  list of params to do the request
   * @param method method http to do te request
   * @returns Observable with data request
   */
  requestMethod(http:HttpClient,url:string, param, method:string,isFileResponse?: boolean) {
    try {
      switch (method) {
        case 'post':
          return http.post(this.currentUrl+url, param, { headers: this.headers });
          break;
        case 'get':
          console.log(param,'param in get');
          
          if(isFileResponse) return http.get(`${this.currentUrl}${url}`,{ params:param,responseType: 'arraybuffer' });
          return param?http.get(`${this.currentUrl}${url}`, { headers: this.headers, params: this.getHttpParams(param)}):
                        http.get(`${this.currentUrl}${url}`, { headers: this.headers });
          break
        case 'put':
          return http.put(this.currentUrl+url, param, { headers: this.headers });
          break;
        case 'patch':
          return http.patch(this.currentUrl+url, param, { headers: this.headers });
          break;
        case 'delete':
          return http.delete(this.currentUrl+url,{ headers: this.headers });
          break;
        default:
            return http.patch(this.currentUrl+url, param, { headers: this.headers });
            break;
      }
    } catch (error) {
      console.log(error,' error in http');
      throw new ErrorEvent(error);

    }
  }

  showMessage(toastr :ToastrService,from:string, align:string,message:string,type:string,title:string){
    toastr.show(`<span class="now-ui-icons ui-1_bell-53"></span> ${message}`, `${title}`, {
      timeOut: 8000,
      closeButton: true,
      enableHtml: true,
      toastClass: `alert ${type} alert-with-icon`,
      positionClass: 'toast-' + from + '-' +  align
    });
  }

  getHttpParams(params: any): HttpParams {    
    const keys = Object.keys(params);
    let httpParams = new HttpParams();
    
    for (let index = 0; index < keys.length; index++) {      
      const key = keys[index];
      httpParams = httpParams.append(key, params[key]);      
    } 
        
    return httpParams;
  }

  removeNullKeys(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] !== null) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }

  getMonthByNumber(month:string){
    switch (month) {
      case '1':
        return 'Ene';
        break;
      case '2':
        return 'Feb';
        break;
      case '3':
        return 'Mar';
        break;
      case '4':
        return 'Abr';
        break;
      case '5':
        return 'May';
        break;
      case '6':
        return 'Jun';
        break;
      case '7':
        return 'Jul';
        break;
      case '8':
        return 'Ago';
        break;
      case '9':
        return 'Sep';
        break;
      case '10':
        return 'Oct';
        break;
      case '11':
        return 'Nov';
        break;
      case '12':
        return 'Dic';
        break;
    }

  }
  formatDate(date: Date): string {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


}
