import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Schedule } from '../../../Models/shcedule.model';
import { User } from '../../../Models/user.model';
import { ScheduleService } from '../../../Services/schedule.service';
import { UserService } from '../../../Services/user.service';
import { AuthService } from '../../../Services/auth.service';
import { AffectationPointService } from '../../../Services/affectation-point.service';
import { AffectationPoint } from '../../../../app/Models/affectationPoint.model';
import { DataSelectsMonitoring } from '../../../../dto/data-selects-monitoring';

@Component({
  selector: 'app-general-filter',
  templateUrl: './general-filter.component.html',
  styleUrls: ['./general-filter.component.scss']
})
export class GeneralFilterComponent implements OnInit {

  filtersForm : FormGroup;
  affectationPoints : AffectationPoint[];
  schedules: Schedule[];
  users: User[];
  user: User;
  appearancePercentageOptions :DataSelectsMonitoring[];
  appearanceFrequencyOptions  :DataSelectsMonitoring[];
  constructor(
    private _formBuilder: FormBuilder,
    private readonly scheduleService: ScheduleService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly affectationPointService: AffectationPointService,
  ) { }

  ngOnInit() {
    this.getData();
  }
  initForm(){
    this.filtersForm =  this._formBuilder.group({
      userId:[],
      affectationPointsIds:[],
      scheduleId:[],
      startDate:[],
      endDate:[new Date()],
      affectationPercentage: [],
      appearanceFrequency:   [],
    });
  }
  setOptions(){
    this.appearancePercentageOptions = [
      {value:'1%-10% RESTRINGIDA',name:'1%-10% RESTRINGIDA'},
      {value:'11%-50% lOCALIZADA',name:'11%-50% lOCALIZADA'},
      {value:'51%-90% EXTENSIVA' ,name:'51%-90% EXTENSIVA'},
      {value:'91%-100% EXTENDIDA',name:'91%-100% EXTENDIDA'},
    ];
    this.appearanceFrequencyOptions = [
      {value:'UNA ÚNICA VEZ'         ,name:'UNA ÚNICA VEZ'},
      {value:'APARICIÓN INTERMITENTE',name:'APARICIÓN INTERMITENTE'},
      {value:'PRECENCCIA FRECUENTE'  ,name:'PRECENCCIA FRECUENTE'},
    ]
  }
  async getData(){
    this.user = await this.authService.user;
    this.setOptions();
    await this.getUsers();
    await this.getSchedules();
    await this.getAffectationPoints();
    this.initForm();
  }

  
  async getSchedules() {
    this.schedules = await this.scheduleService.getSchedules() as Schedule[];
  }
  async getUsers(){
    this.users = await this.userService.getUsers() as User[];
  }
  async getAffectationPoints(){
    this.affectationPoints = await this.affectationPointService.getAffectationPoints() as AffectationPoint[];
  }

  getFilters(){
    const form = this.filtersForm.value    
    form['startDate'] = new Date(form['startDate']).toISOString();
    form['endDate'] = new Date(form['endDate']).toISOString();
    const ids = [];
    for(let index in form['affectationPointsIds']){

      if(form['affectationPointsIds'][index]) ids.push(form['affectationPointsIds'][index].id);
    }
    form['affectationPointsIds']= ids;    
    return form;
  }

}
