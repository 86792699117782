import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit {
  userFilters:FormGroup;
  @Output() filters = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.setForm();
  }
  setForm(){
    this.userFilters = this.formBuilder.group({
      name:[],
      lastName:[],
      countryId:[],
      email:[],
      role:[],
      job:[],
      identification:[],
      isActive:[]
    });
  }
  filterData(){
    console.log(this.userFilters.value);
    this.filters.emit(this.userFilters.value);
  }
  clean(){
    this.userFilters.reset();
    this.filters.emit(this.userFilters.value);
  }
}
