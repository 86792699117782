import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from '../../../../app/Models/user.model';
import { Area } from '../../../../app/Models/area.model';
import { Factor } from '../../../../app/Models/factors.model';
import { Variable } from '../../../../app/Models/variables.model';
import { AreaService } from '../../../Services/area.service';
import { UserService } from '../../../Services/user.service';
import { FactorService } from '../../../Services/factor.service';
import { VariableService } from '../../../Services/variable.service';
import { CountryService } from '../../../Services/country.service';
import { Country } from '../../../Models/country.model';
import { DataSelectsMonitoring } from '../../../../dto/data-selects-monitoring';

@Component({
  selector: 'app-affectation-point-filter',
  templateUrl: './affectation-point-filter.component.html',
  styleUrls: ['./affectation-point-filter.component.scss']
})
export class AffectationPointFilterComponent implements OnInit {
  filtersForm : FormGroup;
  users: User[];
  areas: Area[];
  factores: Factor[];
  variables: Variable[];
  countries: Country[];
  positiveRepercussionsOptions:DataSelectsMonitoring[];
  actualRepercussionsOptions  :DataSelectsMonitoring[] ;
  internalOptions             :DataSelectsMonitoring[] ;
  appearancePercentageOptions :DataSelectsMonitoring[];
  appearanceFrequencyOptions  :DataSelectsMonitoring[];
  @Output() filters = new EventEmitter();
  constructor(
  private  formBuilder: FormBuilder,
  private   areaService:AreaService,
  private  factorService:FactorService,
  private  variableService:VariableService,
  private  userService: UserService,
  private countryService:CountryService
  ) { }

  ngOnInit() {
    this.getData()
  }
  setForm(){
    this.filtersForm = this.formBuilder.group({
      userId:[],
      areaId:[],
      factorId:[],
      variableId:[],
      countryId:[4],
      isActualRepercussion:[],
      isPositiveRepercussion:[],
      isInternal:[],
      affectationPercentage:[],
      appearanceFrequency:[],
    });
  }
  async getData(){
    await this.getUsers();
    await this.getFactors();
    await this.getCountries();
    await this.getAreas();
    this.setOptions();
    this.setForm();
  }
  setOptions(){
    this.positiveRepercussionsOptions = [
      {value:true,name:'Positiva'},
      {value:false,name:'Negativa'},
    ]
    this.actualRepercussionsOptions = [
      {value:true,name:'Actual'},
      {value:false,name:'Potencial'},
    ];
    this.internalOptions = [
      {value:true,name:'Interno'},
      {value:false,name:'Externo'},
    ]
    this.appearancePercentageOptions = [
      {value:'1%-10% RESTRINGIDA',name:'1%-10% RESTRINGIDA'},
      {value:'11%-50% lOCALIZADA',name:'11%-50% lOCALIZADA'},
      {value:'51%-90% EXTENSIVA' ,name:'51%-90% EXTENSIVA'},
      {value:'91%-100% EXTENDIDA',name:'91%-100% EXTENDIDA'},
    ];
    this.appearanceFrequencyOptions = [
      {value:'UNA ÚNICA VEZ'         ,name:'UNA ÚNICA VEZ'},
      {value:'APARICIÓN INTERMITENTE',name:'APARICIÓN INTERMITENTE'},
      {value:'PRECENCCIA FRECUENTE'  ,name:'PRECENCCIA FRECUENTE'},
    ]
  }
  async getAreas(){
    try {
    
      this.areas = await this.areaService.getAreas(this.filtersForm?this.filtersForm.get('countryId').value: 4 ) as Area[];
      console.log(this.areas);
      
    }
    catch (error) {
      console.log(error);
    }
  }
  async getFactors(){
    try {
      this.factores = await this.factorService.getFactors() as Factor[];
    }
    catch (error) {
      console.log(error);
    }
  }
  async getVariables(){
    try {
      this.variables = await this.variableService.getVariables(this.filtersForm.get('factorId').value) as Variable[];
    }
    catch (error) {
      console.log(error);
    }
  }
  async getUsers(){
    try {
      const list = await this.userService.getUsers({isActive:true} ) as User[];
      this.users = list.map(user => {
        user['name'] = user['name'] + ' ' + user['lastName'];
        return user;
      });
    }
    catch (error) {
      console.log(error);
    }
  }
  async getCountries(){
    try {
      this.countries = await this.countryService.getCountries() as Country[];
    }
    catch (error) {
      console.log(error);
    }
  }
  filterData(){
    console.log(this.filtersForm.value);
    this.filters.emit({...this.filtersForm.value});
  }

  clean(){
    this.filtersForm.reset();
    this.filters.emit({...this.filtersForm.value});
  }

}
