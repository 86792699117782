import { StatusModel } from './../Models/status_model.model';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { BasicService } from './basic.service';
import { HttpClient } from '@angular/common/http';
import { Response } from '../../dto/response';

@Injectable({
  providedIn: 'root'
})
export class StatusModelService extends BasicService{

constructor(
  private http:HttpClient,
  private  toastr: ToastrService
  ) {
  super();
 }
 async getStatusModelByModel( model:string ):Promise<StatusModel[]>{
  try{
    const result = (await this.requestMethod(this.http,'/status-model?model='+model,null,'get').toPromise());
    if(result){
      return result as StatusModel[] ;
    }
    return null;
  }catch(e){
    console.log(e);
    this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

  }
return null
}

}
