import { Injectable } from '@angular/core';
import { BasicService } from './basic.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../Models/user.model';
import { Response } from '../../dto/response';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BasicService{

constructor( private http:HttpClient,private toastr:ToastrService) {  super();}

  async getUser(id:number):Promise<User>{
    try{
      const result : User = (await this.requestMethod(this.http,'/user/'+id,null,'get').toPromise()) as User;
      if(result){
        return result as User ;
      }
      return null;
    }catch(e){
      this.showErrorMessage(e)

    }
    return null
  }

  async getUsers(params?):Promise<User[] | {total:number,users:User[] } >{
    try{
      const result : User[] = (await this.requestMethod(this.http,'/user',params,'get').toPromise()) as User[];
      if(result){
        return result as User[]  | {total:number,users:User[] };
      }
      return null;
    }catch(e){
      console.log(e.error, 'catching a error');
    }
    return null
  }

  async getMe():Promise<User>{
    try{
      const result = (await this.requestMethod(this.http,'/user/me',null,'get').toPromise());
      if(result){
        return result as User ;
      }
      return null;
    }catch(e){
      this.showErrorMessage(e);
    }
    return null
  }

  async createUser(user:User):Promise<User>{
    try{
      const result : User = (await this.requestMethod(this.http,'/user',user,'post').toPromise()) as User;
      if(result){
        this.showMessage(this.toastr,'top','center',result.email,'alert-success','Éxito');
        return result ;
      }
      return null;
    }catch(e){
      this.showErrorMessage(e);
    }
    return null
  }

  async updateUser(user:User):Promise<User>{
    try{
      delete user['password2']
      if(user.password==='') delete user.password;
      const result : User = (await this.requestMethod(this.http,`/user/${user.id}`,user,'patch').toPromise()) as User;
      if(result){
        this.showMessage(this.toastr,'top','center',result.email,'alert-success','Éxito');
        return result as User ;
      }
      return null;
    }catch(e){
      this.showErrorMessage(e);

    }
    return null
  }

  async deleteUser(id:number):Promise<boolean>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/api/user/'+id,null,'delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Éxito');
        return result.status;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return false;
    }catch(e){
      this.showErrorMessage(e);

    }
    return false
  }

  async uploadImageUser( file:File,user:User ):Promise<User>{
    try{
      const formData = new FormData();
      formData.append('file',file);
      const result : Response = (await this.requestMethod(this.http,`/user/${user.id}/upload`,formData,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Éxito');
        return result.new as User ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      this.showErrorMessage(e);

    }
    return null
  }
  showErrorMessage(e){
    console.log(e);
    this.showMessage(this.toastr,'top','center',`Error en el servidor: ${e.error.error}-${e.error.message}-${e.error.statusCode}`,'alert-danger','Error');
  }
}

