import { Response } from './../../dto/response';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Factor } from '../Models/factors.model';

@Injectable({
  providedIn: 'root'
})
export class FactorService extends BasicService{

constructor(
  private http: HttpClient,
  private toastr: ToastrService
) { super(); }

  async getFactors():Promise<Factor[]>{
    try{
      const result  = (await this.requestMethod(this.http,'/factor',null,'get').toPromise()) ;
      if(result){
        return result as Factor[] ;
      }
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async saveFactor(factor:Factor):Promise<Factor>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/factor',factor,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Factor ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async updateFactor(factor:Factor):Promise<Factor>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/factor/'+factor.id,factor,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Factor ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async deleteFactor(id:number):Promise<boolean>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/factor/'+id,null,'delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return true;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return false;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return false
  }

}
