import { Section } from './../../../Models/section.model';
import { Country } from './../../../Models/country.model';
import { DataBasicForm } from './../../../../dto/data-basic-form';
import { CountryService } from './../../../Services/country.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subsection } from '../../../Models/subsection.model';
import { DynamicDialogRef } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-basic-form',
  templateUrl: './basic-form.component.html',
  styleUrls: ['./basic-form.component.scss']
})
export class BasicFormComponent implements OnInit {
  @Input('data') data:Section|Subsection;
  @Output ('saveEvent') saveEvent = new EventEmitter<Section|Subsection>();
  basicForm:FormGroup;
  countries:Country[] = [];

  constructor(
    private toastr:ToastrService,
    private _countryService:CountryService,
    private fromBuilder:FormBuilder,
    public ref: DynamicDialogRef,

  ) { }

  ngOnInit() {
    this.getCountries();
    this.setForm(this.data)

  }

  setForm(data?:Subsection|Section){
    this.basicForm = this.fromBuilder.group({
      id:data?.id ?? null,
      name:[data?.name??'',[Validators.required,Validators.minLength(3)]],
      country:[data?data?.country?.id:null,Validators.required],
    })
    this.countries.length>0 ? this.basicForm.get('country').setValue(data?data?.country?.id:null):console.log('no countries '+this.countries.length);
    ;

  }
  submitForm(){
    this.data = this.basicForm.value;
    this.data.country = this.countries.find(country=>country.id==this.basicForm.value.country);
    this.basicForm.valid?
    this.saveEvent.emit(this.data as  Section | Subsection):
    this._countryService.showMessage(this.toastr,'top','center','Complete el formulario','alert-warning','Error');;
  }
  close(){
    this.saveEvent.emit(null);
  }
  async getCountries(){
    try {
      this.countries = await this._countryService.getCountries();

    } catch (error) {
      console.log(error);

    }
  }

}
