import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from './basic.service';
import { Auth} from '../Models/auth.model'
import { User } from '../Models/user.model';
import { ToastrService } from 'ngx-toastr';
import { Response } from '../../dto/response';
@Injectable({
  providedIn: 'root'
})
export class AuthService extends BasicService{
  private _user: User;
  private _token: string;
constructor(private http:HttpClient,private toastr:ToastrService) {
  super();
}

  async login(params):Promise<Auth>{
    try{
      const result : Auth = (await this.requestMethod(this.http,'/auth/login',params,'post').toPromise()) as Auth;
      return result;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error al iniciar sesión','alert-danger','Error');
    }
    return null
  }

  async passwordRecovery(email: String): Promise<any> {
    try {
      const result :any =(await this.requestMethod(this.http,'/auth/password_recovery',{email:email},'post').toPromise()) as Response;
      this.showMessage(this.toastr,'top','center',result.message,'alert-success','Éxito');
      return result;
    } catch (e) {
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error correo no existe','alert-danger','Error');
    }
  }

  logout(): void {
    this._token = null;
    this._user = null;
    localStorage.removeItem('tokenAU');
    localStorage.removeItem('user');
    localStorage.clear();
  }

  public get user(): User {
    if (this._user != null) {
      return this._user;
    } else if (this._user == null && localStorage.getItem('user') != null) {
      this._user = JSON.parse(localStorage.getItem('user')) as User;
      return this._user;
    }
    return null;
  }

  isAuthenticated(): boolean {
    let payload = this.getDataToken(localStorage.getItem('tokenAU'));   
     
    if (payload != null && payload.iat && payload.exp) {
      return true;
    }
    return false;
  }

  getRoles(): string[] {
    let payload = JSON.parse(localStorage.getItem('user'));       
    if (payload != null) {
      return payload.roles;
    }
  }
  getDataToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split(".")[1]));
    }
    return null;
  }
  async resetPassword(params):Promise<Response>{
    try{
      const result : any = (await this.requestMethod(this.http,'/auth/password_reset',params,'post').toPromise()) ;
      if(result['message']){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Éxito');
        return result ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-error','Error');
      return null;
    }catch(e){
      this.showMessage(this.toastr,'top','center',e.message,'alert-error','Error');
    }
    return null
  }
  checkRole(expectedRole: string[]): boolean {
    const roles = this.getRoles();    
    return expectedRole.some(role => roles.indexOf(role) >= 0);

  }
}
