import { Area } from './../../../Models/area.model';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-area-form-modal',
  templateUrl: './area-form-modal.component.html',
  styleUrls: ['./area-form-modal.component.scss']
})
export class AreaFormModalComponent implements OnInit {
  area:Area
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.area = config.data
  }

  ngOnInit() {
  }

  saveEvent($event) {
    console.log($event);

    $event ? this.ref.close($event) : this.ref.destroy();
  }

}
