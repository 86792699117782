import { CountryService } from "../../../Services/country.service";
import { Country } from "../../../Models/country.model";
import { Component, OnInit } from "@angular/core";
import {
  DynamicDialogRef,
  DynamicDialogConfig,
} from "primeng-lts/dynamicdialog";
import { DataBasicForm } from "../../../../dto/data-basic-form";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Section } from "../../../Models/section.model";
import { Subsection } from "../../../Models/subsection.model";

@Component({
  selector: "app-basic-form-modal",
  templateUrl: "./basic-form-modal.component.html",
  styleUrls: ["./basic-form-modal.component.scss"],
})
export class BasicFormModalComponent implements OnInit {
  configData: DataBasicForm;
  dataForm: Section | Subsection;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.configData = config.data;
    this.dataForm = {
      id: this.configData.id,
      name: this.configData.name,
      country: this.configData.country,
    };
  }

  ngOnInit() {}

  saveEvent(data: Subsection | Section) {
    data ? this.ref.close(data) : this.ref.destroy();
  }
}
