import { Response } from './../../dto/response';
import { Monitoring } from './../Models/monitoring.model';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from '../../../src/dto/pagination';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService extends BasicService{

  constructor(
    public httpClient: HttpClient,
    private toastr:ToastrService
  ) { super() }

  async getMonitorings(params: Pagination) :Promise<Monitoring[]| {total:number,monitorings:Monitoring[]} > {
    try {
      const result =  (await this.requestMethod(this.httpClient,'/monitoring',params,'get').toPromise());
      if(result){
        return result as Monitoring[] | {total:number,monitorings:Monitoring[]};
      }
    } catch (error) {
      console.log(error);
      
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.error.message,'alert-danger','Error');
    }
  }
  async saveMonitoring(monitoring:Monitoring):Promise<Monitoring>{
    try {
      const result  =  (await this.requestMethod(this.httpClient,'/monitoring',monitoring,'post').toPromise());
      if(result){
        this.showMessage(this.toastr,'top','center','Monitoreo creado','alert-success','Success');
        return result as Monitoring
      }
      this.showMessage(this.toastr,'top','center','No se creó el monitoreo','alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async updateMonitoring(monitoring:Monitoring):Promise<Monitoring>{
    try {
      const result   =  (await this.requestMethod(this.httpClient,'/monitoring/'+monitoring.id,monitoring,'patch').toPromise());
      if(result){
        this.showMessage(this.toastr,'top','center','Monitoreo actualizado','alert-success','Success');
        return result as Monitoring
      }
      this.showMessage(this.toastr,'top','center','Falló la actualización del monitoreo','alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async deleteMonitoring(monitoring:Monitoring):Promise<any>{
    try {
      const result  =  (await this.requestMethod(this.httpClient,'/monitoring/'+monitoring.id,'','delete').toPromise());
      if(result){
        this.showMessage(this.toastr,'top','center','Monitoreo eliminado','alert-success','Success');
        return result;
      }
      this.showMessage(this.toastr,'top','center','Falló al eliminar el monitoreo','alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async getExcelReport(params : HttpParams){
    
    return this.requestMethod(this.httpClient,'/monitoring/generate',params,'get',true);
  }

  async importData(file:FormData){
    return await this.requestMethod(this.httpClient,'/monitoring/import-data',file,'post')
  }

}
