import { Response } from './../../dto/response';
import { Injectable } from '@angular/core';
import { BasicService } from './basic.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Variable } from '../Models/variables.model';
import { Pagination } from '../..//dto/pagination';

@Injectable({
  providedIn: 'root'
})
export class VariableService extends BasicService {

constructor(
  private http: HttpClient,
  private toastr: ToastrService
) { super(); }

  async getVariables(factor?,params?:Pagination):Promise<{total:number,variables:Variable[]} | Variable[]>{
    try{
      factor = factor?factor:'all';
      const result  = (await this.requestMethod(this.http,'/variable/'+factor,params,'get').toPromise());
      if(result){
        return params? result as {total:number,variables:Variable[]}:result as Variable[] ;
      }
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async saveVariable(variable:Variable):Promise<Variable>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/variable',variable,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Variable ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async updateVariable(variable:Variable):Promise<Variable>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/variable/'+variable.id,variable,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Variable ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async deleteVariable(id:number):Promise<boolean>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/variable/'+id,null,'delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return true;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return false;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return false
  }

}
