import { Response } from './../../dto/response';
import { AffectationPoint } from './../Models/affectationPoint.model';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from '../../dto/pagination';

@Injectable({
  providedIn: 'root'
})
export class AffectationPointService extends BasicService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) { super() }

  async getAffectationPoints(areaId:string = 'all',params?: Pagination) :Promise< {total:number,affectationPoints:AffectationPoint[]} |AffectationPoint[]> {
    try{
      const result : any = (await this.requestMethod(this.http,'/affectation-point/'+areaId,params,'get').toPromise()) as Response;
      if(result){
        return result as AffectationPoint[] ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
  return null
  }

  async updateAffectationPoint(affectationPoint:AffectationPoint):Promise<AffectationPoint>{
    try{
      const result : any = (await this.requestMethod(this.http,'/affectation-point/'+affectationPoint.id,affectationPoint,'patch').toPromise()) as Response;
      if(result){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result as AffectationPoint ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async saveAffectationPoint(affectationPoint:AffectationPoint):Promise<AffectationPoint>{
    try{
      const result : any = (await this.requestMethod(this.http,'/affectation-point',affectationPoint,'post').toPromise()) as Response;
      if(result){
        this.showMessage(this.toastr,'top','center','Punto de afectación creado','alert-success','Success');
        return result as AffectationPoint ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async deleteAffectationPoint(affectationPoint:AffectationPoint):Promise<any>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/affectation-point/'+affectationPoint.id,'','delete').toPromise()) as Response;
      if(result){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.error.message,'alert-danger','Error');

    }
    return null
  }

}
