import { Response } from './../../dto/response';
import { Injectable } from '@angular/core';
import { BasicService } from './basic.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Section } from '../Models/section.model';

@Injectable({
  providedIn: 'root'
})
export class SectionService extends BasicService {

constructor(
  private http: HttpClient,
  private toastr: ToastrService
 ) { super(); }

 async getSections():Promise<Section[]>{
   try{
     const result = (await this.requestMethod(this.http,'/section/all',null,'get').toPromise());
     if(result){
       return result as Section[] ;
     }
     return null;
   }catch(e){
     console.log(e);
     this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

   }
   return null
 }

  async saveSection(section:Section):Promise<Section>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/section',section,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Section ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async updateSection(section:Section):Promise<Section>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/section/'+section.id,section,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Section ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async deleteSection(section:Section):Promise<boolean>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/section/'+section.id,'','delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return true ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

}
