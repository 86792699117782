import { MonitoringService } from './../../../Services/monitoring.service';
import { AffectationPointService } from './../../../Services/affectation-point.service';
import { Monitoring } from './../../../Models/monitoring.model';
import { AffectationPoint } from './../../../Models/affectationPoint.model';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-monitoring-form-modal',
  templateUrl: './monitoring-form-modal.component.html',
  styleUrls: ['./monitoring-form-modal.component.scss']
})
export class MonitoringFormModalComponent implements OnInit {
  affectationPoint: AffectationPoint;
  monitoring      : Monitoring;
  update          :boolean
  constructor(
    public ref:DynamicDialogRef,
    public config:DynamicDialogConfig,
    private _monitoringService: MonitoringService
  ) {
    this.affectationPoint = this.config?.data?.affectationPoint;
    this.monitoring       = this.config?.data?.monitoring;
    this.update           = this.config?.data?.update
  }

  ngOnInit() {
  }

  storeData(formValue){
    formValue?
    this.saveMonitoring(formValue as Monitoring):
    this.ref.destroy();
  }
  async cancel(){
    this.ref.destroy()
  }

  async saveMonitoring(monitoring : Monitoring){
    if(monitoring){
      const monitoringSaved = !this.update? await this._monitoringService.saveMonitoring(monitoring):
                                          await this._monitoringService.updateMonitoring(monitoring);
      monitoringSaved?this.ref.close(monitoringSaved):null;
    }
  }

}
