import { RoleService } from './../../../Services/role.service';
import { Role } from './../../../Models/user.model';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-role-form-modal',
  templateUrl: './role-form-modal.component.html',
  styleUrls: ['./role-form-modal.component.scss']
})
export class RoleFormModalComponent implements OnInit {
  role:Role
  constructor(
    public ref:DynamicDialogRef,
    public config:DynamicDialogConfig,
    public roleService:RoleService
  ) {
    this.role = this.config?.data?.role;
  }

  ngOnInit() {
  }

  async storeData(data){
    const role : Role =  await this.roleService.saveRole(data);
    this.ref.close(role);

  }

}
