import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataSelectsMonitoring } from '../../../../dto/data-selects-monitoring';

@Component({
  selector: 'app-Institutional-response-filter',
  templateUrl: './Institutional-response-filter.component.html',
  styleUrls: ['./Institutional-response-filter.component.scss']
})
export class InstitutionalResponseFilterComponent implements OnInit {
  filterForm: FormGroup;
  @Output() filter = new EventEmitter<any>();
  evaluationOptions: DataSelectsMonitoring[] = []
  generalOptions: DataSelectsMonitoring[] = []
  constructor(private formBuilder:FormBuilder) { }

  ngOnInit() {
    this.setOptions();
    this.setForm();
  }

  setForm(){
    this.filterForm = this.formBuilder.group({
      startDate: [new Date()],
      endDate: [null],
      evaluation: [null],
      staff: [null],
      time: [null],
      budget: [null],
      knowledge: [null],
      resources: [null],
    })
  }
  setOptions(){
    this.evaluationOptions = [
      { value: 'INSIGNIFICANTES', name: 'INSIGNIFICANTES' },
      { value: 'MENORES',         name: 'MENORES' },
      { value: 'SIGNIFICATIVAS',  name: 'SIGNIFICATIVAS' },
    ]
    this.generalOptions = [
      {name:'NINGUNA CAPACIDAD',value:'NINGUNA CAPACIDAD'},
      {name:'CAPACIDAD BAJA',value:'CAPACIDAD BAJA'},
      {name:'CAPACIDAD MEDIA',value:'CAPACIDAD MEDIA'},
      {name:'CAPACIDAD ALTA',value:'CAPACIDAD ALTA'},
    ]
  }
  clean(){
    this.filterForm.reset();
    this.filter.emit(this.filterForm.value);
  }
  filterData(){
    const form = this.filterForm.value    
    form['startDate'] = new Date(form['startDate']).toISOString();
    form['endDate'] = new Date(form['endDate']).toISOString();
    console.log(form);
    this.filter.emit(form);
  }

}
