import { NgModule } from '@angular/core';
import {DynamicDialogModule} from 'primeng-lts/dynamicdialog';
import {InputTextModule} from 'primeng-lts/inputtext';
import {InputTextareaModule} from 'primeng-lts/inputtextarea';
import {FileUploadModule} from 'primeng-lts/fileupload';
import {DropdownModule} from 'primeng-lts/dropdown';
import {TableModule} from 'primeng-lts/table';
import {ButtonModule} from 'primeng-lts/button';
import {SelectButtonModule} from 'primeng-lts/selectbutton';
import {GalleriaModule} from 'primeng-lts/galleria';
import {MultiSelectModule} from 'primeng-lts/multiselect';
import { ChipModule } from 'primeng-lts/chip';
import {CalendarModule} from 'primeng-lts/calendar';
import {StepsModule} from 'primeng-lts/steps';
import { PaginatorModule } from 'primeng-lts/paginator';

const modules = [
  DynamicDialogModule,
  InputTextModule,
  InputTextareaModule,
  FileUploadModule,
  DropdownModule,
  TableModule,
  ButtonModule,
  SelectButtonModule,
  GalleriaModule,
  MultiSelectModule,
  ChipModule,
  CalendarModule,
  StepsModule,
  PaginatorModule,  
]

@NgModule({
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ],
  declarations: [],
  providers: [],
})
export class PrimeNgModule { }
