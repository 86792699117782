import { Coordinate } from './../Models/coordinate.model';
import { Response } from './../../dto/response';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CoordinateService extends BasicService{

constructor(
  private httpClient: HttpClient,
  public toastr:ToastrService
) { super() }
  async getCoordinates() :Promise<Coordinate[]> {
    try {
      const result : Response  =  (await this.requestMethod(this.httpClient,'/api/coordinates',null,'get').toPromise()) as Response;
      if(result.status){
        return result.data as Coordinate[]
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }
  async saveCoordinate(coordinate:Coordinate):Promise<Coordinate>{
    try {
      const result : Response  =  (await this.requestMethod(this.httpClient,'/api/coordinates',coordinate,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.new as Coordinate
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }
  async updateCoordinate(coordinate:Coordinate):Promise<Coordinate>{
    try {
      const result : Response  =  (await this.requestMethod(this.httpClient,'/api/coordinates/'+coordinate.id,coordinate,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.new as Coordinate
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async deleteCoordinate(coordinate:Coordinate):Promise<Coordinate>{
    try {
      const result : Response  =  (await this.requestMethod(this.httpClient,'/api/coordinates/'+coordinate.id,null,'delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Coordinate
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }
}
