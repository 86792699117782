import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  public data:Array<any>;
  public options:any;
  public labels:Array<any>;
  public colors:any[]=[];
  public type;
  public chartColor;
  public canvas : any;
  public ctx;
  constructor() { }

  ngOnInit() {
  }
  chartHovered(event){}
  chartClicked(event){}
  initChart(){
    this.chartColor = "#FFFFFF";
    this.canvas = document.getElementById("bigDashboardChart");
    this.ctx = this.canvas.getContext("2d");
  }
}
