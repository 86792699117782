import { Role } from './../../../Models/user.model';
import { Permission } from './../../../Models/permission.model';
import { RoleService } from './../../../Services/role.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {
  roleForm: FormGroup;
  permissions : Permission[]=[];
  @Input() role:Role;
  @Output() saveEvent = new EventEmitter();
  constructor(
    private formBuilder:FormBuilder,
    private _roleService:RoleService,
    private _toastr:ToastrService
  ) { }

  ngOnInit() {
    this.getPermissions();
    this.setForm(this.role);
  }

  async getPermissions(){
    try {
      this.permissions = await this._roleService.getPermissions();
    } catch (error) {
      console.log(error);
    }
  }
  setForm(role:Role){
    this.roleForm = this.formBuilder.group({
      id: [role ?role.id:null],
      roleName: [role?role.roleName:'',[Validators.required,Validators.minLength(3)]],
      permissions: [role?role.permissions:[],[Validators.required]]
    });
  }

  saveForm(){
    this.roleForm.valid?
    this.saveEvent.emit(this.roleForm.value):
    this._roleService.showMessage(this._toastr,'top','center','Complete el formulario','alert-danger','Error');
  }

  close(){
    this.saveEvent.emit(false);
  }

  removePermission(permission){
    const index = this.roleForm.value.permissions.indexOf(permission);
    this.roleForm.value.permissions.splice(index,1);
  }



}
