import { SubsectionService } from './../../../Services/subsection.service';
import { SectionService } from './../../../Services/section.service';
import { CountryService } from './../../../Services/country.service';
import { Country } from './../../../Models/country.model';
import { Subsection } from './../../../Models/subsection.model';
import { Area } from './../../../Models/area.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Section } from '../../../Models/section.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss']
})
export class AreaFormComponent implements OnInit {

  @Input('area') area: Area
  @Output() saveEvent = new EventEmitter()
  areaForm:FormGroup;
  sections:Section[]=[]
  subsections:Subsection[]=[]
  countries:Country[]=[]
  constructor(
    private _formBuilder : FormBuilder,
    private _countryService:CountryService,
    private _sectionService:SectionService,
    private _subsectionService:SubsectionService,
    public toastr:ToastrService
  ) { }

  ngOnInit() {
    this.getData()

  }
  async setForm(area?: Area){
    this.areaForm = this._formBuilder.group({
      id:                    [area?.id??null],
      code:                  [area?.code??null,Validators.required],
      areaBufferZone:        [area?.areaBufferZone??null,Validators.required],
      areaNominatedProperty: [area?.areaNominatedProperty??null,Validators.required],
      segment:               [area?.segment??null,Validators.required],
      nominatedProperty:     [area?.nominatedProperty??null,Validators.required],
      averageRoadWidth:      [area?.averageRoadWidth??null,Validators.required],
      lengthRoad:            [area?.lengthRoad??null,Validators.required],
      section:               [area?.section?.id??null,Validators.required],
      subsection:            [area?.subsection?.id??null,Validators.required],
      country:               [area?.country?.id??null,Validators.required],
    })
  }

  async getCountries(){
    this.countries = await this._countryService.getCountries()
  }

  async getSections(){
    this.sections = await this._sectionService.getSections()
  }
  async getSubsections(){
    this.subsections = await this._subsectionService.getSubsections()
  }
  async getData(){
    await this.getCountries()
    await this.getSections()
    await this.getSubsections()
    this.setForm(this.area)

  }
  submitForm(){
    if(this.areaForm.valid) {
      const data = this.areaForm.value
      data.country =  this.countries.find(c=>c.id==data.country)
      data.section =  this.sections.find(s=>s.id==data.section)
      data.subsection =  this.subsections.find(s=>s.id==data.subsection)
       console.log(data);
      this.saveEvent.emit(data as Area)
    }else{
      this._countryService.showMessage(this.toastr,'top','center','Complete el formulario ','alert-danger','Error');
    }

  }
  cancel(){
    this.saveEvent.emit(null)
  }

}
