import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { UserForm } from '../../../../../src/dto/user-form';
import { User } from '../../../Models/user.model';

@Component({
  selector: 'app-user-form-modal',
  templateUrl: './user-form-modal.component.html',
  styleUrls: ['./user-form-modal.component.scss']
})
export class UserFormModalComponent implements OnInit {
  user:User
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    this.user = config?.data?.user;
  }

  ngOnInit() {
  }

  storeData(formValue){
    this.ref.close(formValue as UserForm);
  }
  cancel(){
    this.ref.destroy()
  }
}
