import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasicService } from './basic.service';
import { ToastrService } from 'ngx-toastr';
import { Schedule } from '../Models/shcedule.model';
import { Response } from '../../dto/response';
import { Pagination } from '../..//dto/pagination';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends BasicService {

constructor(
  private http : HttpClient,
  private toastr: ToastrService) {
  super();
}
async getSchedules(params?: Pagination):Promise<Schedule[]| {total:number, schedules:Schedule[]} >{
  try{
    const result = (await this.requestMethod(this.http,'/schedule/all',params,'get').toPromise());
    if(result){
      return result as Schedule[] ;
    }
    return null;
  }catch(e){
    console.log(e);
    this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

  }
return null
}
  async updateSchedule(schedule:Schedule):Promise<Schedule>{
    try{
      const id = schedule.id
      delete schedule.id
      const result = (await this.requestMethod(this.http,'/schedule/'+id,schedule,'patch').toPromise());
      if(result){
        return result as Schedule ;
      }
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

    async saveSchedule(schedule:Schedule):Promise<Schedule>{
      try{
        if(schedule.id == null)  delete schedule.id;
        const result = (await this.requestMethod(this.http,'/schedule',schedule,'post').toPromise());
        if(result){
          return result as Schedule ;
        }
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }

  }
  async deleteSchedule(id:number){
     try {
     return  await this.requestMethod(this.http,'/schedule/'+id,id,'delete').toPromise()
    } catch (error) {
      console.log(error);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.error.message,'alert-danger','Error');
    }
  }


}
