import { ScheduleService } from './../../../Services/schedule.service';
import { Schedule } from './../../../Models/shcedule.model';
import { UserService } from './../../../Services/user.service';
import { MonitoringService } from './../../../Services/monitoring.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AffectationPointService } from './../../../Services/affectation-point.service';
import { AylluFileService } from './../../../Services/ayllu-file.service';
import { AylluFile } from './../../../Models/AylluFile.model';
import { AffectationPoint } from './../../../Models/affectationPoint.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataSelectsMonitoring } from '../../../../../src/dto/data-selects-monitoring';
import { AreaService } from '../../../../app/Services/area.service';
import { VariableService } from '../../../../app/Services/variable.service';
import { Area } from '../../../../app/Models/area.model';
import { Variable } from '../../../../app/Models/variables.model';
import { Monitoring } from '../../../Models/monitoring.model';

@Component({
  selector: 'app-monitoring-form',
  templateUrl: './monitoring-form.component.html',
  styleUrls: ['./monitoring-form.component.scss']
})
export class MonitoringFormComponent implements OnInit {

  @Input('monitoring') monitoring: Monitoring;
  @Input('affectationPoint') affectationPoint:  AffectationPoint;
  @Input('update') update:  Boolean;
  @Output() saveEvent = new EventEmitter();

  monitoringForm:FormGroup;
  positiveRepercussionsOptions:DataSelectsMonitoring[];
  actualRepercussionsOptions  :DataSelectsMonitoring[] ;
  internalOptions             :DataSelectsMonitoring[] ;
  affectationPercentageOptions :DataSelectsMonitoring[];
  appearanceFrequencyOptions  :DataSelectsMonitoring[];
  isContinueAffectationOptions  :DataSelectsMonitoring[];
  selectedFiles:File[] = [];
  files        :AylluFile[]=[];
  schedules    : Schedule[]=[];
  affectationPoints: AffectationPoint[]=[];
  variables: Variable[] = [];
  areas: Area[]= [];
  constructor(
    private _formBuilder : FormBuilder,
    private _monitoringService:MonitoringService,
    private _schedule:ScheduleService,
    private _userService:UserService,
    private _aylluFileService:AylluFileService,
    public  toastr:ToastrService,
    private _areaService:AreaService,
    private _variableService:VariableService,
  ) { }

  ngOnInit() {
    this.getSchedules();
    this.setOptions();
    this.getData();
  }
  async getData(){
    await this.getAreas()
    await this.getVariables()
    this.setForm(this.monitoring)
  }

  async setForm(monitoring?: Monitoring){
    this.monitoringForm = this._formBuilder.group({
      id:                    [monitoring?.id??null],
      affectationPercentage:  [monitoring?.affectationPercentage??'',[Validators.required, Validators.min(0),Validators.max(100)]],//
      appearanceFrequency:   [monitoring?.appearanceFrequency??'',[Validators.required, Validators.min(0),Validators.max(100)]],//
      schedule:              [monitoring?.schedule.id??false,Validators.required],//
      affectationPoint:      [monitoring?.affectationPoint??false,Validators.required],//
      concept:               [monitoring?.concept??''],//
    })
    this.files = monitoring?.files??[];
    if (monitoring) {
      this.affectationPoints = monitoring.schedule.affectationPoints;
      this.monitoringForm.get('affectationPoint').setValue(monitoring.affectationPoint);
    }
  }

 async submitForm(){
    if(this.selectedFiles.length==0 && !this.update){
      this.toastr.error('Debe adjuntar al menos un archivo','Error');
      return null;

    }
    if(this.monitoringForm.valid){

      const savedFiles=  this.selectedFiles.map(async file => {
      return await this.saveFile(file);
      })
      const files = this.selectedFiles.length>0? 
                    await Promise.all(savedFiles):
                    this.monitoring && this.monitoring.files ? this.monitoring.files : [];
      const user = await this._userService.getMe();
      const value = this.monitoringForm.value;
      value.id = value.id != null ? value.id :this._aylluFileService.generateUUID();
      if ((files && user) || this.update) {
        const monitoring: Monitoring = {
          ...value,
          user:user,
          files:files,
          schedule:this.schedules.find(schedule=> schedule.id == value.schedule),
        }
        this.saveEvent.emit(monitoring)
      }else {
        this.toastr.error('Elija archivos','Error');

      }
    }else{
      this.toastr.error('Complete los campos requeridos','Error');
    }

  }
  setOptions(){
    this.isContinueAffectationOptions = [
      {value:true,name:'Si'},
      {value:false,name:'No'},
    ]
    this.positiveRepercussionsOptions = [
      {value:true,name:'Positiva'},
      {value:false,name:'Negativa'},
    ]
    this.actualRepercussionsOptions = [
      {value:true,name:'Actual'},
      {value:false,name:'Potencial'},
    ];
    this.internalOptions = [
      {value:true,name:'Interno'},
      {value:false,name:'Externo'},
    ]
    this.affectationPercentageOptions = [
      {value:'1%-10% RESTRINGIDA',name:'1%-10% RESTRINGIDA'},
      {value:'11%-50% lOCALIZADA',name:'11%-50% lOCALIZADA'},
      {value:'51%-90% EXTENSIVA' ,name:'51%-90% EXTENSIVA'},
      {value:'91%-100% EXTENDIDA',name:'91%-100% EXTENDIDA'},
    ];
    this.appearanceFrequencyOptions = [
      {value:'UNA ÚNICA VEZ'         ,name:'UNA ÚNICA VEZ'},
      {value:'APARICIÓN INTERMITENTE',name:'APARICIÓN INTERMITENTE'},
      {value:'PRECENCCIA FRECUENTE'  ,name:'PRECENCCIA FRECUENTE'},
    ]
  }
  async getAreas(){
    this.areas = await this._areaService.getAreas() as Area[];
  }
  async getVariables(){
    this.variables = await this._variableService.getVariables() as Variable[];
  }
  async getSchedules(){
    this.schedules = await this._schedule.getSchedules() as Schedule[];
  }
  cancel(){
    this.saveEvent.emit(null);
  }

  onSelect(event){
    for(let file of event.files) {
      this.selectedFiles.push(file);
  }
  }

  async saveFile(file:File){

    const formData = new FormData();
    formData.append('file',file);
    formData.append('id',this._aylluFileService.generateUUID());
    formData.append('url',null);
    formData.append('isShared','false');
    formData.append('name',file.name);
    formData.append('type',file.type);
    const uploadedFile = await this._aylluFileService.uploadFile(formData);
    return uploadedFile;

  }
  setAffectationPoints(event){
    const plan = this.schedules.find(schedule=>schedule.id==event.value);
    this.affectationPoints = plan.affectationPoints;    
  }

}
