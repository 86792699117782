import { DomSanitizer, SafeUrl } from '@angular/platform-browser';import { AffectationPoint } from './../../../Models/affectationPoint.model';
import { AreaService } from './../../../Services/area.service';
import { VariableService } from './../../../Services/variable.service';
import { Area } from '../../../Models/area.model';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Variable } from '../../../Models/variables.model';
import { ToastrService } from 'ngx-toastr';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { AylluFile } from './../../../Models/AylluFile.model';
import { AylluFileService } from './../../../Services/ayllu-file.service';
import { AuthService } from '../../../../app/Services/auth.service';
import { DataSelectsMonitoring } from '../../../../dto/data-selects-monitoring';
import { FileUpload } from 'primeng-lts/fileupload';

@Component({
  selector: 'app-affectation-point',
  templateUrl: './affectation-point-form.component.html',
  styleUrls: ['./affectation-point-form.component.scss']
})
export class AffectationPointFormComponent implements OnInit {
  @Input() affectationPoint: any;
  @Output() saveEvent = new EventEmitter();
  @ViewChild('fileButton') fileButton:FileUpload;
  affectationPointForm :FormGroup;
  areas: Area[];
  variables: Variable[];
  fileToUpload: File = null;
  file?:AylluFile;
  url?:SafeUrl = null;
  positiveRepercussionsOptions:DataSelectsMonitoring[];
  actualRepercussionsOptions  :DataSelectsMonitoring[] ;
  internalOptions             :DataSelectsMonitoring[] ;
  appearancePercentageOptions :DataSelectsMonitoring[];
  appearanceFrequencyOptions  :DataSelectsMonitoring[];

  constructor(
    private _formBuilder :FormBuilder,
    private _areaService:AreaService,
    private _variableService:VariableService,
    private _fileService:AylluFileService,
    private _authService:AuthService,
    public toastr:ToastrService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.setOptions();
    this.getData();
  }
  async setForm( affectationPoint?:AffectationPoint ){
    this.affectationPointForm = this._formBuilder.group({
      id:                  [affectationPoint?.id??null],
      area:                [affectationPoint?.area?.id??null,Validators.required],
      variable:            [affectationPoint?.variable?.id??null,Validators.required],
      latitude:           [affectationPoint?.latitude??null,Validators.required],
      longitude:          [affectationPoint?.longitude??null,Validators.required],
      altitude:           [affectationPoint?.altitude??null,Validators.required],
      affectationPercentage: [affectationPoint?.affectationPercentage??null,],
      appearanceFrequency:   [affectationPoint?.appearanceFrequency??null,],
      altitude2:           [affectationPoint?.altitude2??null],
      latitude2:           [affectationPoint?.latitude2??null],
      longitude2:          [affectationPoint?.longitude2??null],
      isActualRepercussion:          [affectationPoint?.isActualRepercussion??null],
      isPositiveRepercussion:          [affectationPoint?.isPositiveRepercussion??null],
      isInternal:          [affectationPoint?.isInternal??null],
      concept:          [affectationPoint?.concept??null],
    })
  }

  async getAreas(){
    const user = this._authService.user;
    this.areas = await this._areaService.getAreas(user.country.id.toString()) as Area[]
  }
  async getVariables(){
    this.variables = await this._variableService.getVariables() as Variable[];
  }
  async getData(){
    await this.getAreas()
    await this.getVariables()
    this.setForm(this.affectationPoint)
  }

  async submitForm(){
    if (this.affectationPointForm.valid) {

      let formData = this.affectationPointForm.value;
      const { area,variable } = formData;

      const data  = {
        ...formData,
        area: this.areas.find(_area=>_area.id==area),
        variable:this.variables.find(_variable=>_variable.id==variable),
        isContinueAffectation:formData['latitude']!=null } as AffectationPoint
      data.id = data.id??this._fileService.generateUUID();
      if (this.fileToUpload) {
        const fileForm = new FormData();
        fileForm.append('file',this.fileToUpload);
        fileForm.append('id',this._fileService.generateUUID());
        fileForm.append('name',this.fileToUpload.name);
        fileForm.append('type',this.fileToUpload.type);
        const file = await this._fileService.saveAylluFile(fileForm); 
        data.urlImage = file.url;
      }
      data.user = await this._authService.user;
      this.saveEvent.emit(data)
    }else {
      this._areaService.showMessage(this.toastr, 'top','center','Por favor complete el formulario ','alert-danger','Error');
    }
  }
  cancel(){
    this.saveEvent.emit(null)
  }
  onUpload(event) {
    const file = event.files[0];
    this.fileToUpload = file;     
    this.url = this.sanitizer.bypassSecurityTrustUrl(file.objectURL.changingThisBreaksApplicationSecurity);
    
  }

  setOptions(){
    this.positiveRepercussionsOptions = [
      {value:true,name:'Positiva'},
      {value:false,name:'Negativa'},
    ]
    this.actualRepercussionsOptions = [
      {value:true,name:'Actual'},
      {value:false,name:'Potencial'},
    ];
    this.internalOptions = [
      {value:true,name:'Interno'},
      {value:false,name:'Externo'},
    ]
    this.appearancePercentageOptions = [
      {value:'1%-10% RESTRINGIDA',name:'1%-10% RESTRINGIDA'},
      {value:'11%-50% lOCALIZADA',name:'11%-50% lOCALIZADA'},
      {value:'51%-90% EXTENSIVA' ,name:'51%-90% EXTENSIVA'},
      {value:'91%-100% EXTENDIDA',name:'91%-100% EXTENDIDA'},
    ];
    this.appearanceFrequencyOptions = [
      {value:'UNA ÚNICA VEZ'         ,name:'UNA ÚNICA VEZ'},
      {value:'APARICIÓN INTERMITENTE',name:'APARICIÓN INTERMITENTE'},
      {value:'PRECENCCIA FRECUENTE'  ,name:'PRECENCCIA FRECUENTE'},
    ]
  }
  quitImage(){
    this.url = null;
    this.fileToUpload = null;
    this.fileButton.clear();
  }

}
