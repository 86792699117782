import {
  NgModule
} from '@angular/core';
import {
  CommonModule,
} from '@angular/common';
import {
  BrowserModule
} from '@angular/platform-browser';
import {
  Routes,
  RouterModule
} from '@angular/router';

import {
  AdminLayoutComponent
} from './layouts/admin-layout/admin-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

const routes: Routes = [{
    path: '',
    redirectTo: 'start',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },{
    path: '',
    component: PublicLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/public-layout/public-layout.module#PublicLayoutModule'
    }]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [],
})
export class AppRoutingModule {}
