import { Response } from './../../dto/response';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Answer } from '../Models/answer.model';
import { Pagination } from '../../dto/pagination';

@Injectable({
  providedIn: 'root'
})
export class AnswerService extends BasicService{

constructor(
  public httpClient: HttpClient,
  private toastr:ToastrService
) { super()}

  async getAnswers(params?:Pagination) :Promise<Answer[] | {total:number,institutionalResponses:Answer[]}> {
    try {
      const result  =  (await this.requestMethod(this.httpClient,'/institutional-response',params,'get').toPromise());
      if(result){
        return result as Answer[]
      }
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async saveAnswer(answer:Answer):Promise<Answer>{
    try {
      const result  = (await this.requestMethod(this.httpClient,'/institutional-response',answer,'post').toPromise());
      if(result){
        this.showMessage(this.toastr,'top','center','Respuesta institucional creada','alert-success','Success');
        return result as Answer
      }
      this.showMessage(this.toastr,'top','center','Respuesta institucional creada','alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async updateAnswer(answer:Answer):Promise<Answer>{
    try {
      const result = (await this.requestMethod(this.httpClient,'/institutional-response/'+answer.id,answer,'put').toPromise()) as Answer;
      if(result){
        this.showMessage(this.toastr,'top','center',`Respuesta institucional con id: ${result.id} fue actualizada`,'alert-success','Success');
        return result
      }
      this.showMessage(this.toastr,'top','center',`Respuesta institucional con id: ${result.id} no se pudo actualizar`,'alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async deleteAnswer(answer:Answer):Promise<Answer>{
    try {
      const result   =  (await this.requestMethod(this.httpClient,'/institutional-response/'+answer.id,'','delete').toPromise()) as Answer;
      if(result){
        this.showMessage(this.toastr,'top','center',`Respuesta institucional con id: ${answer.id} eliminada  `,'alert-success','Success');
        return result
      }
      this.showMessage(this.toastr,'top','center',`Respuesta institucional id: ${answer.id} no se pudo eliminar`,'alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }
}
