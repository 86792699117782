import { Country } from './../../../Models/country.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng-lts/dynamicdialog';

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.scss']
})
export class CountryFormComponent implements OnInit {
  countryForm:FormGroup
  country:Country;

  constructor(private _formBuilder:FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
    ) {
      console.log(config,'is config');

      this.country = config?.data;
      this.setForm(this.country);
    }

  ngOnInit() {

  }

  setForm(country:Country){
    this.countryForm = this._formBuilder.group({
      id: [country?country.id:''],
      url: [country?country.url:'',[Validators.minLength(3)]],
    })
  }
  submitForm(){
    this.country.url = this.countryForm.value.url;
    this.countryForm.value.url!= ''?
    this.ref.close(this.country): this.ref.close(null);

  }
  close(){
    this.ref.destroy();
  }
}
