import { Response } from './../../dto/response';
import { Subsection } from './../Models/subsection.model';
import { Injectable } from '@angular/core';
import { BasicService } from './basic.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SubsectionService  extends BasicService{

constructor(
  private http: HttpClient,
  private toastr: ToastrService
) { super() }

async getSubsections():Promise<Subsection[]>{
    try{
      const result  = (await this.requestMethod(this.http,'/subsection/all',null,'get').toPromise());
      if(result){
        return result as Subsection[] ;
      }
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
  return null
  }
async updateSubsection(subsection:Subsection):Promise<Subsection>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/subsection/'+subsection.id,subsection,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Subsection ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async saveSubsection(subsection:Subsection):Promise<Subsection>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/subsection',subsection,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.data as Subsection ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return null
  }

  async deleteSubsection(subsection:Subsection):Promise<boolean>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/subsection/'+subsection.id,'','delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return true;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return false;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center','Error en el servidor '+e.statusText,'alert-danger','Error');

    }
    return false
  }

}
