import { Response } from './../../dto/response';
import { AylluFile } from './../Models/AylluFile.model';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AylluFileService extends BasicService {

  constructor(
    private httpClient: HttpClient,
    public toastr:ToastrService
  ) { super() }

  async getAylluFiles() :Promise<AylluFile[]> {
    try {
      const result  =  (await this.requestMethod(this.httpClient,'/ayllu-file',null,'get').toPromise());
      if(result){
        return result as AylluFile[]
      }
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }
  async saveAylluFile(aylluFile:FormData):Promise<AylluFile>{
    try {
      const result  =  (await this.requestMethod(this.httpClient,'/ayllu-file',aylluFile,'post').toPromise())
      if(result){
        this.showMessage(this.toastr,'top','center','Archivo creado','alert-success','Success');
        return result as AylluFile
      }
      this.showMessage(this.toastr,'top','center','Falló al crear el archivo','alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }

  async deleteAylluFile(aylluFile:AylluFile):Promise<AylluFile>{
    try {
      const result  =  (await this.requestMethod(this.httpClient,'/ayllu-file/'+aylluFile.id,null,'delete').toPromise());
      if(result){
        this.showMessage(this.toastr,'top','center','Archivo eliminado','alert-success','Success');
        return result as AylluFile
      }
      this.showMessage(this.toastr,'top','center','Error al eliminar el archivo','alert-danger','Error');
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }
  }
  async uploadFile(data:FormData):Promise<AylluFile> {
    try {
      const result  =  (await this.requestMethod(this.httpClient,'/ayllu-file',data,'post').toPromise());
      if(result){
        this.showMessage(this.toastr,'top','center','Archivo subido','alert-success','Success');
        return result as AylluFile
      }
      return null;
    } catch (error) {
      this.showMessage(this.toastr,'top','center','Error en el servidor '+error.message,'alert-danger','Error');
    }

  }
}
