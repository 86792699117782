import { User } from './../../../Models/user.model';
import { Area } from './../../../Models/area.model';
import { Schedule } from './../../../Models/shcedule.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AreaService } from '../../../Services/area.service';
import { UserService } from '../../../Services/user.service';
import { ToastrService } from 'ngx-toastr';
import { StatusModelService } from '../../../Services/statusModel.service';
import { StatusModel } from '../../../Models/status_model.model';
import { AffectationPoint } from './../../../Models/affectationPoint.model';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss']
})
export class ScheduleFormComponent implements OnInit {

  @Input('schedule') schedule: Schedule;
  @Input('affectationPoints') affectationPoints: AffectationPoint[];
  @Output() saveEvent = new EventEmitter()
  scheduleForm:FormGroup;
  areas:Area[]=[]
  users:User[]=[]
  statusModel:StatusModel[]=[]
  constructor(
    private _areaService: AreaService,
    private _userService:UserService,
    private _statusModel:StatusModelService,
    private toastr :ToastrService,
    private formBuilder :FormBuilder
  ) { }

  ngOnInit() {
    this.getData();
  }

  setForm(schedule?:Schedule){    
    this.scheduleForm  = this.formBuilder.group({
      id:                    [schedule?.id??null],
      status:                [schedule?.status??null],
      observations:          [schedule?.observations??null],
      code:                  [schedule?.code??null,Validators.required],
      dateMonitoring:        [schedule?new Date(schedule.dateMonitoring):new Date(),Validators.required],
      coordinator:           [schedule?.coordinator??null,Validators.required],
      monitors:              [schedule?.monitors??null,Validators.required],
      affectationPoints:     [schedule?.affectationPoints??null],
    })
  }

  async getAreas(){
    this.areas =  await this._areaService.getAreas() as Area[];

  }
  async getUsers(){
    this.users =  await this._userService.getUsers({isActive:true}) as User[];
  }
  async getStatusModel (){
    this.statusModel = await this._statusModel.getStatusModelByModel('schedule');
  }

async getData(){
  await this.getAreas()
  await this.getUsers()
  await this.getStatusModel();
    this.setForm(this.schedule)
  }

  submitForm(){
    if (this.scheduleForm.valid) {
      this.saveEvent.emit({...this.scheduleForm.value})
    }else{
      this._areaService.showMessage(this.toastr,'top','center','Complete el formulario ','alert-danger','Error')
    }
  }
  cancel(){
    this.saveEvent.emit(null)
  }

}
