import { GalleryModalComponent } from './../modals/gallery-modal/gallery-modal.component';
import { DialogService } from 'primeng-lts/dynamicdialog';
import { AylluFile } from './../../Models/AylluFile.model';
import { CONFIG } from './../../config/config';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DataTable } from '../../../dto/data-table';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  providers: [DialogService]
})
export class DynamicTableComponent implements OnInit {
  @Input() data: DataTable;
  @Input() cols: Headers;
  @Input() totalRecords: number;
  @Output() tableEvent = new EventEmitter();
  @Output() changePageEvent = new EventEmitter();
  url:string = `${CONFIG.url_api}`
  rows:number=10;
  first:number=1;
  constructor(
    public dialogService: DialogService,
  ) {

  }

  ngOnInit() { }
  eventTable(col,row){
    col.data = row
    this.tableEvent.emit(col);
  }

  openGallery(images:AylluFile[]){
    const ref = this.dialogService.open(GalleryModalComponent, {
      contentStyle: { overflow: "auto" },
      baseZIndex: 10000,
      width: "70%",
      height: "90%",
      data: images,
    });
  }
  onPageChange(event){
    this.changePageEvent.emit(event);
  }

}
