import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from '../Models/permission.model';
import { BasicService } from './basic.service';
import { Auth } from '../Models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService extends BasicService {
  constructor( http:HttpClient){
    super();
  }
  storeToken(token:string){
    localStorage.setItem('tokenAU',token);
  }
  getToken(){
    return localStorage.getItem('tokenAU');
  }
  deleteToken(){
    localStorage.removeItem('tokenAU');
  }
  storeRoles(roles:string[]){
    localStorage.setItem('roles',JSON.stringify(roles));
  }
  isAuthorized(role:string){

    let roles:string[] = JSON.parse(localStorage.getItem('roles'));

    if(roles){
      let result:boolean = roles.find(x=>x == role) != null;

      return result;
    }
    return false;
  }
  storeBasicUserInfo(auth: Auth){
    delete auth.token;
    localStorage.setItem('user',JSON.stringify(auth));
  }
}
