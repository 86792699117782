import { DynamicDialogRef, DynamicDialogConfig } from 'primeng-lts/dynamicdialog';
import { Schedule } from './../../../Models/shcedule.model';
import { Component, OnInit } from '@angular/core';
import { AffectationPoint } from './../../../Models/affectationPoint.model';
import { AffectationPointService } from '../../../Services/affectation-point.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit {
  schedule:Schedule
  data: any
  affectationPoints: AffectationPoint[] =[];
  constructor(
    public ref :DynamicDialogRef,
    public config:DynamicDialogConfig,
    private affectationPointService: AffectationPointService,
    private toasrService:ToastrService,
  ) {
    this.data = config;
    this.schedule = this.data.data;

  }

  ngOnInit() {
    this.getAffectationPoints();
  }

  storeData(formValue){
    this.ref.close(formValue as Schedule);
  }

  cancel(){
    this.ref.destroy();
  }
  async getAffectationPoints(){
    try {
      this.affectationPoints = await this.affectationPointService.getAffectationPoints() as AffectationPoint[];
    }catch (error) {
      this.affectationPointService.showMessage(
        this.toasrService,
        'top',
        'center',
        'Error al cargar los puntos de afectación',
        'error',
        'Error',
        );
    }
  }



}
