import { AnswerService } from './../../../Services/answer.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { Monitoring } from './../../../Models/monitoring.model';
import { Component, OnInit } from '@angular/core';
import { Answer } from '../../../Models/answer.model';

@Component({
  selector: 'app-answer-form-modal',
  templateUrl: './answer-form-modal.component.html',
  styleUrls: ['./answer-form-modal.component.scss']
})
export class AnswerFormModalComponent implements OnInit {

  answer    :Answer
  monitoring:Monitoring
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _answerService: AnswerService
  ) {
    this.answer = config.data.answer
    this.monitoring = config.data.monitoring
   }

  ngOnInit() {
  }

  async saveEvent($event) {
    $event['userId'] = $event['user']['id'];
    $event['monitoringId'] = $event['monitoring']['id'];
    delete $event['user'];
    delete $event['monitoring'];
    const answer = $event && $event?.id ? await this._answerService.updateAnswer($event):$event?
                        await this._answerService.saveAnswer($event):this.ref.destroy();
    answer?this.ref.close(answer):null;
  }

}
