import { Response } from './../../dto/response';
import { Role } from './../Models/user.model';
import { BasicService } from './basic.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Permission } from '../Models/permission.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BasicService {

constructor(
  private http:HttpClient,
  private toastr:ToastrService
 ) { super(); }

 async getRoles () :Promise<Role[]> {

    try{
      const result : Response = (await this.requestMethod(this.http,'/api/role',null,'get').toPromise()) as Response;
      if(result.status){
        return result.data as Role[] ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;

    }catch(e){

    }
  }
 async getPermissions () :Promise<Permission[]> {

    try{
      const result : Response = (await this.requestMethod(this.http,'/api/permissions',null,'get').toPromise()) as Response;
      if(result.status){
        return result.data as Permission[] ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;

    }catch(e){

    }
  }
  async updateRole(role:Role):Promise<Role>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/api/role/'+role.id,role,'put').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.new as Role ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center',e.error.message,'alert-danger','Error');

    }
    return null
  }

  async saveRole(role:Role):Promise<Role>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/api/role',role,'post').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.new as Role ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center',e.error.message,'alert-danger','Error');

    }
    return null
  }
  async deleteRole(id:number):Promise<boolean>{
    try{
      const result : Response = (await this.requestMethod(this.http,'/api/role/'+id,null,'delete').toPromise()) as Response;
      if(result.status){
        this.showMessage(this.toastr,'top','center',result.message,'alert-success','Success');
        return result.status as boolean ;
      }
      this.showMessage(this.toastr,'top','center',result.message,'alert-danger','Error');
      return null;
    }catch(e){
      console.log(e);
      this.showMessage(this.toastr,'top','center',e.error.message,'alert-danger','Error');

    }
    return null
  }
}
