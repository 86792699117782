import { TokenService } from './../../Services/token.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../Services/auth.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    permission:string[]
}
export const ROUTES: RouteInfo[] = [
  { path: '/areas',               title: 'Areas',  icon:'location_map-big', class: '' ,permission:['admin','superAdmin','user']},
  { path: '/dashboard',           title: 'Dashboard',  icon: 'design_app', class: '' ,permission:[]},
  { path: '/schedule',               title: 'Calendario',  icon:'users_single-02', class: '' ,permission:['admin','superAdmin']},
  // { path: '/roles',               title: 'Roles',  icon: 'pi pi-lock', class: '' ,permission:[]},
  //{ path: '/icons',               title: 'Icons',  icon:'education_atom', class: '' ,permission:[]},
  { path: '/factors',             title: 'Factores',  icon:'business_chart-bar-32', class: '' ,permission:['admin','superAdmin','user']},
  { path: '/institutional-response', title: 'Respuesta Ins.',  icon:'pi pi-book', class: '' ,permission:['admin','superAdmin']},
  { path: '/affectation-points',  title: 'Puntos de afectación',  icon:'pi pi-map-marker', class: '' ,permission:['admin','superAdmin','user']},
  // { path: '/notifications',          title: 'Notifications',  icon:'ui-1_bell-53', class: '' ,permission:[]},
  { path: '/monitorings',         title: 'Monitoreos',  icon:'pi pi-map-marker', class: '' ,permission:['admin','superAdmin','user']},
  { path: '/countries',           title: 'Países',  icon: 'business_globe', class: '' ,permission:['admin','superAdmin']},
  { path: '/user-profile',           title: 'Perfil de usuario',  icon:'users_single-02', class: '' ,permission:['admin','superAdmin','user']},
    { path: '/sections',            title: 'Secciones',  icon:'location_map-big', class: '' ,permission:['superAdmin']},
    { path: '/subsections',         title: 'Subsecciones',  icon:'location_map-big', class: '' ,permission:['superAdmin']},
    { path: '/users',               title: 'Usuarios',  icon: 'pi pi-users', class: '' ,permission:['admin','superAdmin']},
    { path: '/variables',           title: 'Variables',  icon:'business_chart-bar-32', class: '' ,permission:['admin','superAdmin','user']},
    { path: '/affectation-point-report', title: 'Reporte- Puntos de afectación',  icon:'business_chart-bar-32', class: '' ,permission:['admin','superAdmin','user']},

].sort();

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    private tokenService: TokenService,
    private authService:AuthService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem.permission.every(permission => this.authService.checkRole([permission])));
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };
}
